
import {
    computed,
    defineComponent, PropType,
    reactive,
    ref, Ref, watch
} from 'vue';
import { user } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import { isCNServer } from '@/util/location';
import noticeBase from '@/util/notice.base';
import { phoneCode, community, account } from '@/data';
import { ComBuildType } from '@/components/view/installer/community/build-tree/util';
import { getPhoneCodeOps } from '@/util/phone-code';
import { PeopleData } from './all.type';
import {
    IdentityOptions,
    enableLandLineCallType,
    unableLandLineCallType,
    sipCallOptions,
    defaultCallType
} from './util';

export default defineComponent({
    emits: ['close', 'update'],
    props: {
        initForm: {
            type: Object as PropType< PeopleData >,
            required: true
        },
        operate: {
            type: String as PropType< OperateType >,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.ProperAllTextNewPeople,
            edit: WordList.EditPeople
        };
        const form: Ref< any > = ref(null);

        const formData = reactive({ ...props.initForm });
        formData.HasIntercomAccess = formData.HasIntercomAccess === '1';
        formData.Language = Lang;
        const showResetPw = props.initForm.Email !== '' && props.operate === 'edit';

        const rules = reactive({
            FirstName: [{
                required: true,
                message: WordList.RuleFirstNameEmpty,
                trigger: 'blur'
            }],
            LastName: [{
                required: true,
                message: WordList.RuleLastNameEmpty,
                trigger: 'blur'
            }],
            EmployeeID: [{
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextID),
                trigger: 'blur'
            }],
            Email: [{
                // 若初始email为空，则不进行空校验
                required: props.initForm.Email !== '',
                message: WordList.RuleEmailEmpty,
                trigger: 'blur'
            }, {
                validator: user.checkEmail,
                trigger: 'change'
            }],
            MobileNumber: [{
                // 若初始mobile为空，则不进行空校验
                required: props.initForm.MobileNumber !== '',
                message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber),
                trigger: 'blur'
            }]
        });

        // 国内默认区号86
        if (isCNServer()) {
            formData.PhoneCode = '86';
        }

        // 限制手机号为数字
        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.MobileNumber = val.replace(reg, '');
        };

        // 落地呼叫开关,限制呼叫类型选项
        const callTypeOptions = computed(() => {
            if (!formData.HasIntercomAccess) {
                return defaultCallType;
            }
            return community.IsEnableLandline.value === 1 ? enableLandLineCallType : unableLandLineCallType;
        });

        const url = {
            add: 'v3/web/office/user/addOfficeUser',
            edit: 'v3/web/office/user/modOfficeUser'
        };
        const submit = () => {
            form.value.validate((valid: boolean) => {
                if (valid) {
                    const resultParams = { ...formData };
                    resultParams.Floor = resultParams.Floor.includes('all') ? 'all' : (resultParams.Floor as string[]).join(';');
                    resultParams.HasIntercomAccess = resultParams.HasIntercomAccess ? '1' : '0';
                    HttpRequest.post(url[props.operate], resultParams, () => {
                        emit('close');
                        emit('update');
                    });
                }
            });
        };

        const resetPw = () => {
            HttpRequest.post('comresetpw', {
                ID: formData.ID
            }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, WordList.TabelChangePwStepSuccess, 'success')(() => false);
            }, false]);
        };

        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: WordList.AllFloors
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }

        // 添加时获取department的floor
        if (props.operate === 'add') {
            community.getCommunityBuild((res: {
                data: Array<ComBuildType>;
            }) => {
                res.data.forEach((item) => {
                    if (item.ID === formData.DepartmentID && item.Floor) {
                        formData.Floor = item.Floor === 'all' ? ['all'] : item.Floor.split(';');
                    }
                });
            });
        }

        // 最多只能选10个floor层
        function changeFloor() {
            if (formData.Floor.length > 10) {
                (formData.Floor as string[]).pop();
            }
        }

        return {
            form,
            formData,
            IdentityOptions,
            rules,
            langOptions,
            isCNServer: isCNServer(),
            phoneCode,
            limitMobileNumberType,
            callTypeOptions,
            sipCallOptions,
            submit,
            resetPw,
            community,
            showResetPw,
            titles,
            changeFloor,
            floorOptions,
            getPhoneCodeOps
        };
    }
});
