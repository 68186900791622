/* eslint-disable import/prefer-default-export */
const phoneCode = [
    {
        locale: 'AI',
        area: 'Anguilla',
        code: '1-264'
    },
    {
        locale: 'AG',
        area: 'Antigua and Barbuda',
        code: '1-268'
    },
    {
        locale: 'AW',
        area: 'Aruba',
        code: '297'
    },
    {
        locale: 'BS',
        area: 'Bahamas',
        code: '1-242'
    },
    {
        locale: 'BB',
        area: 'Barbados',
        code: '1-246'
    },
    {
        locale: 'BZ',
        area: 'Belize',
        code: '501'
    },
    {
        locale: 'BM',
        area: 'Bermuda',
        code: '1-441'
    },
    {
        locale: 'KY',
        area: 'Cayman Islands',
        code: '1-345'
    },
    {
        locale: 'CN',
        area: '中国',
        code: '86'
    },
    {
        locale: 'CR',
        area: 'Costa Rica',
        code: '506'
    },
    {
        locale: 'CU',
        area: 'Cuba',
        code: '53'
    },
    {
        locale: 'DM',
        area: 'Dominica',
        code: '1-767'
    },
    {
        locale: 'DO',
        area: 'Dominican Republic',
        code: '1-829'
    },
    {
        locale: 'DO',
        area: 'Dominican Republic',
        code: '1809'
    },
    {
        locale: 'DO',
        area: 'Dominican Republic',
        code: '1849'
    },
    {
        locale: 'SV',
        area: 'El Salvador',
        code: '503'
    },
    {
        locale: 'GL',
        area: 'Kalaallit Nunaat',
        code: '299'
    },
    {
        locale: 'GD',
        area: 'Grenada',
        code: '1-473'
    },
    {
        locale: 'GP',
        area: 'Guadeloupe',
        code: '590'
    },
    {
        locale: 'GT',
        area: 'Guatemala',
        code: '502'
    },
    {
        locale: 'HT',
        area: 'Haïti',
        code: '509'
    },
    {
        locale: 'HN',
        area: 'Honduras',
        code: '504'
    },
    {
        locale: 'JM',
        area: 'Jamaica',
        code: '1-876'
    },
    {
        locale: 'MQ',
        area: 'Martinique',
        code: '596'
    },
    {
        locale: 'MX',
        area: 'México',
        code: '52'
    },
    {
        locale: 'MS',
        area: 'Montserrat',
        code: '1-664'
    },
    {
        locale: 'NL',
        area: 'Netherlands Antilles',
        code: '599'
    },
    {
        locale: 'NI',
        area: 'Nicaragua',
        code: '505'
    },
    {
        locale: 'PA',
        area: 'Panamá',
        code: '507'
    },
    {
        locale: 'PR',
        area: 'Puerto Rico',
        code: '1-787'
    },
    {
        locale: 'PR',
        area: 'Puerto Rico',
        code: '1939'
    },
    {
        locale: 'KN',
        area: 'St Kitts and Nevis',
        code: '1-869'
    },
    {
        locale: 'LC',
        area: 'St Lucia',
        code: '1-758'
    },
    {
        locale: 'PM',
        area: 'St Pierre et Miquelon',
        code: '508'
    },
    {
        locale: 'VC',
        area: 'St Vincent Grenadines',
        code: '1-784'
    },
    {
        locale: 'TT',
        area: 'Trinidad and Tobago',
        code: '1-868'
    },
    {
        locale: 'TC',
        area: 'Turks and Caicos Islands',
        code: '1-649'
    },
    {
        locale: 'US/CA',
        area: 'United States/Canada',
        code: '1'
    },
    {
        locale: 'VG',
        area: 'Virgin Islands, British',
        code: '1-284'
    },
    {
        locale: 'VI',
        area: 'Virgin Islands, U.S.',
        code: '1-340'
    },
    {
        locale: 'AR',
        area: 'Argentina',
        code: '54'
    },
    {
        locale: 'BO',
        area: 'Bolivia',
        code: '591'
    },
    {
        locale: 'BR',
        area: 'Brasil',
        code: '55'
    },
    {
        locale: 'CL',
        area: 'Chile',
        code: '56'
    },
    {
        locale: 'CO',
        area: 'Colombia',
        code: '57'
    },
    {
        locale: 'EC',
        area: 'Ecuador',
        code: '593'
    },
    {
        locale: 'FK',
        area: 'Falkland Islands',
        code: '500'
    },
    {
        locale: 'GF',
        area: 'Guyane française',
        code: '594'
    },
    {
        locale: 'GY',
        area: 'Guyana',
        code: '592'
    },
    {
        locale: 'PY',
        area: 'Paraguay',
        code: '595'
    },
    {
        locale: 'PE',
        area: 'Perú',
        code: '51'
    },
    {
        locale: 'SR',
        area: 'Suriname',
        code: '597'
    },
    {
        locale: 'UY',
        area: 'Uruguay',
        code: '598'
    },
    {
        locale: 'VE',
        area: 'Venezuela',
        code: '58'
    },
    {
        locale: 'AL',
        area: 'Shqiperia',
        code: '355'
    },
    {
        locale: 'AD',
        area: 'Andorra',
        code: '376'
    },
    {
        locale: 'AT',
        area: 'Österreich',
        code: '43'
    },
    {
        locale: 'BY',
        area: 'Беларусь',
        code: '375'
    },
    {
        locale: 'BE',
        area: 'Belgique',
        code: '32'
    },
    {
        locale: 'BA',
        area: 'Bosna i Hercegovina',
        code: '387'
    },
    {
        locale: 'BG',
        area: 'България',
        code: '359'
    },
    {
        locale: 'HR',
        area: 'Hrvatska',
        code: '385'
    },
    {
        locale: 'CY',
        area: 'Κύπρος',
        code: '357'
    },
    {
        locale: 'CZ',
        area: 'Česká republika',
        code: '420'
    },
    {
        locale: 'DK',
        area: 'Denmark',
        code: '45'
    },
    {
        locale: 'EE',
        area: 'Eesti',
        code: '372'
    },
    {
        locale: 'FO',
        area: 'Føroyar',
        code: '298'
    },
    {
        locale: 'FI',
        area: 'Suomi / Ahvenanmaa',
        code: '358'
    },
    {
        locale: 'FR',
        area: 'France',
        code: '33'
    },
    {
        locale: 'DE',
        area: 'Deutschland',
        code: '49'
    },
    {
        locale: 'GI',
        area: 'Gibraltar',
        code: '350'
    },
    {
        locale: 'GR',
        area: 'Ελλάδα',
        code: '30'
    },
    {
        locale: 'HU',
        area: 'Magyarország',
        code: '36'
    },
    {
        locale: 'IS',
        area: 'Ísland',
        code: '354'
    },
    {
        locale: 'IS',
        area: 'Éireann',
        code: '353'
    },
    {
        locale: 'IT',
        area: 'Italia',
        code: '39'
    },

    {
        locale: 'XK',
        area: 'Kosova',
        code: '383'
    },
    {
        locale: 'LV',
        area: 'Latvija',
        code: '371'
    },
    {
        locale: 'LI',
        area: 'Liechtenstein',
        code: '423'
    },
    {
        locale: 'LT',
        area: 'Lietuva',
        code: '370'
    },
    {
        locale: 'LU',
        area: 'Lëtzebuerg',
        code: '352'
    },
    {
        locale: 'MK',
        area: 'Македонија',
        code: '389'
    },
    {
        locale: 'MT',
        area: 'Malta',
        code: '356'
    },
    {
        locale: 'MD',
        area: 'Moldova',
        code: '373'
    },
    {
        locale: 'MC',
        area: 'Monaco',
        code: '377'
    },
    {
        locale: 'ME',
        area: 'Crna Gora / Црна Гора',
        code: '382'
    },
    {
        locale: 'NL',
        area: 'Nederland',
        code: '31'
    },
    {
        locale: 'NO',
        area: 'Norge',
        code: '47'
    },
    {
        locale: 'PL',
        area: 'Polska',
        code: '48'
    },
    {
        locale: 'PT',
        area: 'Portugal',
        code: '351'
    },
    {
        locale: 'RO',
        area: 'România',
        code: '40'
    },
    {
        locale: 'SM',
        area: 'San Marino',
        code: '378'
    },
    {
        locale: 'BA',
        area: 'Србија',
        code: '381'
    },
    {
        locale: 'SK',
        area: 'Slovensko',
        code: '421'
    },
    {
        locale: 'SI',
        area: 'Slovenija',
        code: '386'
    },
    {
        locale: 'ES',
        area: 'España',
        code: '34'
    },
    {
        locale: 'SE',
        area: 'Sverige',
        code: '46'
    },
    {
        locale: 'CH',
        area: 'Schweiz',
        code: '41'
    },
    {
        locale: 'CY',
        area: 'Kuzey Kıbrıs Türk Cumhuriyeti',
        code: '9-0392'
    },
    {
        locale: 'UA',
        area: 'Україна',
        code: '380'
    },
    {
        locale: 'GB',
        area: 'United Kingdom',
        code: '44'
    },
    {
        locale: 'VA',
        area: 'Stato della Città del Vaticano',
        code: '379'
    },
    {
        locale: 'AF',
        area: 'Afġānistān',
        code: '93'
    },
    {
        locale: 'AM',
        area: 'Հայաստան',
        code: '374'
    },
    {
        locale: 'AZ',
        area: 'Azərbaycan',
        code: '994'
    },
    {
        locale: 'BH',
        area: 'البحرين‎',
        code: '973'
    },
    {
        locale: 'BD',
        area: 'বাংলাদেশ',
        code: '880'
    },
    {
        locale: 'BT',
        area: ' འབྲུག་ཡུལ་',
        code: '975'
    },
    {
        locale: 'BN',
        area: 'Brunei',
        code: '673'
    },
    {
        locale: 'KH',
        area: 'កម្ពុជា',
        code: '855'
    },
    {
        locale: 'TL',
        area: "Timór Lorosa'e",
        code: '670'
    },
    {
        locale: 'GE',
        area: 'საქართველო',
        code: '995'
    },
    {
        locale: 'HK',
        area: '中國香港',
        code: '852'
    },
    {
        locale: 'IN',
        area: 'Bhārat',
        code: '91'
    },
    {
        locale: 'ID',
        area: 'Indonesia',
        code: '62'
    },
    {
        locale: 'IR',
        area: 'ایران‎',
        code: '98'
    },
    {
        locale: 'IQ',
        area: 'ٱلْعِرَاق‎',
        code: '964'
    },
    {
        locale: 'IL',
        area: 'ישראל',
        code: '972'
    },
    {
        locale: 'JP',
        area: '日本',
        code: '81'
    },
    {
        locale: 'JO',
        area: 'الأردن‎',
        code: '962'
    },
    {
        locale: 'KP',
        area: '한국, 뎀 인민 대표',
        code: '850'
    },
    {
        locale: 'KR',
        area: '대한민국',
        code: '82'
    },
    {
        locale: 'KW',
        area: 'الكويت‎',
        code: '965'
    },
    {
        locale: 'KG',
        area: 'Кыргызстан',
        code: '996'
    },
    {
        locale: 'LA',
        area: 'ລາວ',
        code: '856'
    },
    {
        locale: 'LB',
        area: 'Lebanon',
        code: '961'
    },
    {
        locale: 'MO',
        area: '中國澳門',
        code: '853'
    },
    {
        locale: 'MY',
        area: 'Malaysia',
        code: '60'
    },
    {
        locale: 'MV',
        area: 'ދިވެހިރާއް',
        code: '960'
    },
    {
        locale: 'MN',
        area: 'Монгол Улс Mongol Uls',
        code: '976'
    },
    {
        locale: 'MM',
        area: 'မြန်မာ',
        code: '95'
    },
    {
        locale: 'NP',
        area: 'नेपाल ',
        code: '977'
    },
    {
        locale: 'OM',
        area: 'سلطنة عمان',
        code: '968'
    },
    {
        locale: 'PK',
        area: 'Pakistan',
        code: '92'
    },
    {
        locale: 'PS',
        area: 'Palestinian Territory',
        code: '970'
    },
    {
        locale: 'PH',
        area: 'Pilipinas',
        code: '63'
    },
    {
        locale: 'QA',
        area: 'دولة قطر',
        code: '974'
    },
    {
        locale: 'RU/KZ',
        area: 'Russia/Kazakhstan',
        code: '7'
    },
    {
        locale: 'SA',
        area: 'المملكة العربية السعودية',
        code: '966'
    },
    {
        locale: 'SG',
        area: 'Singapore',
        code: '65'
    },
    {
        locale: 'LK',
        area: 'ශ්‍රී ලංකා',
        code: '94'
    },
    {
        locale: 'SY',
        area: 'سوريا',
        code: '963'
    },
    {
        locale: 'TW',
        area: '中國台灣',
        code: '886'
    },
    {
        locale: 'TJ',
        area: 'Тоҷикистон',
        code: '992'
    },
    {
        locale: 'TH',
        area: 'ประเทศไทย',
        code: '66'
    },
    {
        locale: 'TR',
        area: 'Türkiye',
        code: '90'
    },
    {
        locale: 'TM',
        area: 'Türkmenistan',
        code: '993'
    },
    {
        locale: 'AE',
        area: 'الإمارات العربية المتحدة',
        code: '971'
    },
    {
        locale: 'UZ',
        area: 'Oʻzbekiston',
        code: '998'
    },
    {
        locale: 'VN',
        area: 'Việt Nam',
        code: '84'
    },
    {
        locale: 'YE',
        area: 'ٱلْيَمَن',
        code: '967'
    },
    {
        locale: 'DZ',
        area: 'الجزائر',
        code: '213'
    },

    {
        locale: 'AO',
        area: 'Angola',
        code: '244'
    },
    {
        locale: 'BJ',
        area: 'Bénin',
        code: '229'
    },
    {
        locale: 'BW',
        area: 'Botswana',
        code: '267'
    },
    {
        locale: 'BF',
        area: 'Burkina Faso',
        code: '226'
    },
    {
        locale: 'BI',
        area: 'Burundi',
        code: '257'
    },
    {
        locale: 'CM',
        area: 'Cameroon',
        code: '237'
    },
    {
        locale: 'CV',
        area: 'cabo Verde',
        code: '238'
    },
    {
        locale: 'CF',
        area: 'Afrique centrale',
        code: '236'
    },
    {
        locale: 'TD',
        area: 'Tchad',
        code: '235'
    },
    {
        locale: 'KM',
        area: 'جزر القمر',
        code: '269'
    },
    {
        locale: 'CG',
        area: 'Congo',
        code: '242'
    },
    {
        locale: 'CG',
        area: 'Congo, Dem Rep',
        code: '243'
    },
    {
        locale: 'DJ',
        area: 'جيبوتي',
        code: '253'
    },
    {
        locale: 'EG',
        area: 'مصر',
        code: '20'
    },
    {
        locale: 'GQ',
        area: 'Guinea Ecuatorial',
        code: '240'
    },
    {
        locale: 'ER',
        area: 'Eritrea',
        code: '291'
    },
    {
        locale: 'ET',
        area: 'ኢትዮጵያ',
        code: '251'
    },
    {
        locale: 'GA',
        area: 'Gabon',
        code: '241'
    },
    {
        locale: 'GM',
        area: 'Gambia',
        code: '220'
    },
    {
        locale: 'GH',
        area: 'Ghana',
        code: '233'
    },
    {
        locale: 'GN',
        area: 'Guinée',
        code: '224'
    },
    {
        locale: 'GW',
        area: 'Guinée-Bissau',
        code: '245'
    },
    {
        locale: 'CI',
        area: "Côte d'Ivoire",
        code: '225'
    },
    {
        locale: 'KE',
        area: 'Kenya',
        code: '254'
    },
    {
        locale: 'LS',
        area: 'Lesotho',
        code: '266'
    },
    {
        locale: 'LR',
        area: 'Liberia',
        code: '231'
    },
    {
        locale: 'LY',
        area: 'ليبيا',
        code: '218'
    },
    {
        locale: 'MG',
        area: 'Madagascar',
        code: '261'
    },
    {
        locale: 'MW',
        area: 'Malawi',
        code: '265'
    },
    {
        locale: 'ML',
        area: 'Mali',
        code: '223'
    },
    {
        locale: 'MR',
        area: 'موريتانيا',
        code: '222'
    },
    {
        locale: 'MU',
        area: 'Mauritius',
        code: '230'
    },
    {
        locale: 'MA',
        area: 'المغرب',
        code: '212'
    },
    {
        locale: 'MZ',
        area: 'Moçambique',
        code: '258'
    },
    {
        locale: 'NA',
        area: 'Namibia',
        code: '264'
    },
    {
        locale: 'NE',
        area: 'Niger',
        code: '227'
    },
    {
        locale: 'NG',
        area: 'Nigeria',
        code: '234'
    },
    {
        locale: 'RE/YT',
        area: 'Réunion / Mayotte',
        code: '262'
    },
    {
        locale: 'RW',
        area: 'Rwanda',
        code: '250'
    },
    {
        locale: 'SN',
        area: 'Sénégal',
        code: '221'
    },
    {
        locale: 'SC',
        area: 'Sesel',
        code: '248'
    },
    {
        locale: 'SL',
        area: 'Sierra Leone',
        code: '232'
    },
    {
        locale: 'SO',
        area: 'Soomaaliya',
        code: '252'
    },
    {
        locale: 'ZA',
        area: 'South Africa',
        code: '27'
    },
    {
        locale: 'SS',
        area: 'South Sudan',
        code: '211'
    },
    {
        locale: 'SD',
        area: 'السودان',
        code: '249'
    },
    {
        locale: 'SZ',
        area: 'Swaziland',
        code: '268'
    },
    {
        locale: 'TZ',
        area: 'Tanzania',
        code: '255'
    },
    {
        locale: 'TG',
        area: 'Aller',
        code: '228'
    },
    {
        locale: 'TN',
        area: 'تونس',
        code: '216'
    },
    {
        locale: 'UG',
        area: 'Uganda',
        code: '256'
    },
    {
        locale: 'ZM',
        area: 'Zambia',
        code: '260'
    },
    {
        locale: 'ZW',
        area: 'Zimbabwe',
        code: '263'
    },
    {
        locale: 'AS',
        area: 'American Samoa',
        code: '1-684'
    },
    {
        locale: 'AU/CC/CX',
        area: 'Australia/Cocos/Christmas Island',
        code: '61'
    },
    {
        locale: 'CK',
        area: 'Cook Islands',
        code: '682'
    },
    {
        locale: 'FJ',
        area: 'Fiji',
        code: '679'
    },
    {
        locale: 'PF',
        area: 'Polynésie française',
        code: '689'
    },
    {
        locale: 'GU',
        area: 'Guam',
        code: '1-671'
    },
    {
        locale: 'MH',
        area: 'Marshall Islands',
        code: '692'
    },
    {
        locale: 'FM',
        area: 'Micronesia',
        code: '691'
    },
    {
        locale: 'NC',
        area: 'Nouvelle Calédonie',
        code: '687'
    },
    {
        locale: 'NZ',
        area: 'New Zealand',
        code: '64'
    },
    {
        locale: 'MP',
        area: 'Northern Mariana Islands',
        code: '1-670'
    },
    {
        locale: 'PW',
        area: 'パラオ',
        code: '680'
    },
    {
        locale: 'PG',
        area: 'Papua New Guinea',
        code: '675'
    },
    {
        locale: 'WS',
        area: 'Samoa',
        code: '685'
    },
    {
        locale: 'SB',
        area: 'Solomon Islands',
        code: '677'
    },
    {
        locale: 'TO',
        area: 'Tonga',
        code: '676'
    },
    {
        locale: 'VU',
        area: 'Vanuatu',
        code: '678'
    }
];
export function getPhoneCodeOps() {
    const formatPhoneCodeOps: {
        label: string;
        value: string;
    }[] = [];
    phoneCode.forEach((item) => {
        formatPhoneCodeOps.push({
            value: item.code,
            // label: `${item.area}(+${item.code})`
            label: `(+${item.code})${item.area}`
        });
    });
    return formatPhoneCodeOps;
}
