import { user } from '@/methods/rule';
import { OptionsType } from './all.type';

const IdentityOptions: Array< OptionsType > = [{
    value: '30',
    label: WordList.BasicUser
}, {
    value: '31',
    label: WordList.AdvancedUser
}];

const enableLandLineCallType = [{
    label: WordList.ProperAllTextSmartIndoorOrGuard,
    value: '0'
}, {
    label: WordList.ProperAllTextPhoneIndoorOrGuard,
    value: '1'
}, {
    label: WordList.ProperAllTextSmartPlusIndoorOrGuardBackup,
    value: '2'
}, {
    label: WordList.ProperAllTextIndoorOrGuardSmartPlusBackup,
    value: '3'
}, {
    label: WordList.ProperAllTextIndoorOrGuardPhoneBackup,
    value: '4'
}, {
    label: WordList.ProperAllTextIndoorOrGuardSmartPlusPhone,
    value: '5'
}];

const unableLandLineCallType = [{
    label: WordList.ProperAllTextSmartIndoorOrGuard,
    value: '0'
}, {
    label: WordList.ProperAllTextIndoorOrGuardSmartPlusBackup,
    value: '3'
}];

const defaultCallType = [{
    label: WordList.DefaultCallType,
    value: '0'
}];

const sipCallOptions = [{
    label: WordList.ProperAllTextIPCall,
    value: '1'
}, {
    label: WordList.ProperAllTextSIPCall,
    value: '0'
}];

export default null;
export {
    IdentityOptions,
    enableLandLineCallType,
    unableLandLineCallType,
    sipCallOptions,
    defaultCallType
};
